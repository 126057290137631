const heroImg = document.querySelector('.full-hero__img');
const backdropSections = document.querySelectorAll('.backdrop-section');

for (let i = 0; i < backdropSections.length; i++) {
  const backdropSection = backdropSections[i];
  const backgroundImg = backdropSection.querySelector('.backdrop-section__bg');
  const top = backdropSection.getBoundingClientRect().y;
  backgroundImg.style.top = `${(window.scrollY - (top + window.scrollY))/2}px`;
}

document.addEventListener('scroll', () => {
  for (let i = 0; i < backdropSections.length; i++) {
    const backdropSection = backdropSections[i];
    const backgroundImg = backdropSection.querySelector('.backdrop-section__bg');
    const top = backdropSection.getBoundingClientRect().y;
    backgroundImg.style.top = `${(window.scrollY - (top + window.scrollY))/2}px`;
  }

  if (heroImg) {
    heroImg.style.top = `${window.scrollY/2}px`;
  }
})